import * as React from 'react';
import { css } from '@emotion/core';
import { header, breakpoint, mqUp } from '../../constants/styles';

import Wrapper from '../base/Wrapper';

interface ContentCenterProps {
  props?: object;
}

const ContentCenter: React.SFC<ContentCenterProps> = props => {
  const { children } = props;
  return (
    <Wrapper
      size="S"
      css={css`
        height: 100%;
        padding-bottom: 10rem;
        padding-top: calc(6rem + ${header.heightSmall});
        ${mqUp(breakpoint.mainNav)} {
          padding-top: calc(6rem + ${header.heightBase});
        }
      `}
    >
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > * {
            flex-grow: 1;
            width: 100%;
          }
        `}
      >
        {children}
      </div>
    </Wrapper>
  );
};
export default ContentCenter;
