import * as React from 'react';
import { css } from '@emotion/core';
import { mqUp, breakpoint } from '../../constants/styles';
import { WrapperSizesType } from '../../appTypes';

interface WrapperProps {
  className?: string;
  size?: WrapperSizesType;
  children: React.ReactNode;
}

export const sizes = {
  XL: css`
    max-width: 100%;
  `,
  L: css`
    max-width: 180rem;
  `,
  M: css`
    max-width: 110rem;
  `,
  S: css`
    max-width: 80rem;
  `,
  XS: css`
    max-width: 48rem;
  `,
};

const Wrapper: React.SFC<WrapperProps> = ({
  children,
  className,
  size = 'M',
}) => (
  <div
    className={className}
    css={css`
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      ${sizes[size]};
      ${mqUp(breakpoint.smartphone)} {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      ${mqUp(breakpoint.mainNav)} {
        padding-left: 4rem;
        padding-right: 4rem;
      }
      ${mqUp(breakpoint.desktopLarge)} {
        padding-left: 6rem;
        padding-right: 6rem;
      }
    `}
  >
    {children}
  </div>
);

export default Wrapper;
