import * as React from 'react';

import Svg from '../../images/logo-recita.svg';

interface LogoProps {
  width?: string;
  height?: string;
  class?: string;
}

const Logo: React.SFC<LogoProps> = ({ width, height, ...rest }) => {
  return (
    <img
      src={Svg}
      alt="Récita architecture Lyon"
      width={width}
      height={height}
      {...rest}
    />
  );
};

export default Logo;
