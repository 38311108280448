module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteLanguage: 'fr', // Language Tag on <html> element
  siteTitle: 'Récita | atelier d’architecture', // Navigation and Site Title
  siteTitleShort: 'Récita | atelier d’architecture', // short_name for manifest
  siteUrl: 'https://www.recita.fr/', // Domain of your site. No trailing slash!
  siteDescription:
    'Récita est un atelier d’architecture, d’urbanisme et de design basé à Lyon et à Clermont-Ferrand. Il est fondé par Christophe Desvignes et Luc Pigeon.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  //userTwitter: '@sutterlity', // Twitter Username
  // ogSiteName: 'sutterlity', // Facebook Site Name
  // ogLanguage: 'fr_FR', // Facebook Language

  // Manifest and Progress color
  themeColor: '#fff',
  backgroundColor: '#fff',
};
