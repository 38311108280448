import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import posed from 'react-pose';
import { route } from '../../constants/app';

import Logo from '../base/Logo';
import Link from '../base/Link';
import Wrapper from '../base/Wrapper';
import NavButton from '../base/NavButton';
import {
  mqUp,
  mqDown,
  color,
  header,
  breakpoint,
} from '../../constants/styles';

interface AppHeaderProps {
  navOpen: boolean;
  toggleNavMobile(): void;
  closeNavMobile(): void;
}

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 666;
  background: rgba(255, 255, 255, 0.95);
`;

const Grid = styled.div`
  ${mqUp(breakpoint.mainNav)} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${header.heightSmall};
    ${mqUp(breakpoint.mainNav)} {
      height: ${header.heightBase};
    }
  }
`;

const GridItemNavPose = posed.div({
  open: { height: 'auto' },
  close: { height: 0 },
});

const GridItemNav = styled(GridItemNavPose)`
  ${mqDown(breakpoint.mainNav)} {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background: ${color.light};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);
  }
  ${mqUp(breakpoint.mainNav)} {
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      margin-left: 3rem;
    }
  }
`;

const GridItemBrand = styled.div`
  display: flex;
  align-items: center;
  ${mqDown(breakpoint.mainNav)} {
    z-index: 2;
    position: relative;
    height: ${header.heightSmall};
  }
`;

const Nav = styled.nav`
  ${mqDown(breakpoint.mainNav)} {
    display: block;
    padding-top: calc(${header.heightSmall} + 2rem);
    padding-bottom: 2rem;
  }
  ${mqUp(breakpoint.mainNav)} {
    display: flex;
    align-items: center;
    > * {
      margin-left: 1rem;
    }
  }
`;

const NavLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 1rem;
  text-decoration: none;
  &.is-active {
    color: ${color.clr1Light};
  }
`;

const NavLinkSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavLinkSocialsLink = styled(Link)`
  display: flex;
  padding: 0.75rem;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

const IconInsta = () => (
  <svg viewBox="0 0 30 30" width="20" height="20">
    <g fill={color.neutral}>
      <path d="M21.5 2h-13A6.508 6.508 0 002 8.5v13C2 25.084 4.916 28 8.5 28h13c3.584 0 6.5-2.916 6.5-6.5v-13C28 4.916 25.084 2 21.5 2zM26 21.5c0 2.481-2.019 4.5-4.5 4.5h-13A4.505 4.505 0 014 21.5v-13C4 6.019 6.019 4 8.5 4h13C23.981 4 26 6.019 26 8.5v13z" />
      <path d="M14.978 7.978A7.03 7.03 0 007.957 15a7.03 7.03 0 007.021 7.022A7.03 7.03 0 0022 15a7.03 7.03 0 00-7.022-7.022zm0 12.044A5.027 5.027 0 019.957 15a5.027 5.027 0 015.021-5.022A5.028 5.028 0 0120 15a5.028 5.028 0 01-5.022 5.022zM22 5c-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.551-.449-1-1-1z" />
    </g>
  </svg>
);

const IconFacebook = () => (
  <svg viewBox="0 0 30 30" width="16" height="16">
    <path
      fill={color.neutral}
      d="M19 5h3.5c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5h-4C14.8 0 12 2.8 12 6.5V11H7.5c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5H12v13.5c0 .3.2.5.5.5h4c.3 0 .5-.2.5-.5V16h4.5c.2 0 .4-.2.5-.4l1-4c0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2H17V7c0-1.1.9-2 2-2z"
    />
  </svg>
);

const IconAjap = () => (
  <svg viewBox="0 0 60 60" width="32" height="32">
    <path
      fill={color.neutral} fill-rule="evenodd" d="M13.425 3.915h-.254v13.87h3.435v-3.309h3.945v4.963H24.114v8.78h-3.945v-3.182h-3.435v6.617H27.422v11.198h3.436V38.78h4.199v17.305h3.435V51.25H46V40.561h-7.508V28.22H27.678v-8.78h3.435v-3.436h-7.125V3.915H13.426zm21.632 27.74h-4.2v3.69h4.2v-3.69zm3.435 12.342v3.817h4.072v-3.817h-4.072zM16.607 7.35v3.69h3.945V7.35h-3.945z" clip-rule="evenodd"/>
  </svg>
);

const AppHeader: React.SFC<AppHeaderProps> = ({
  navOpen,
  toggleNavMobile,
  closeNavMobile,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper size="XL">
      <Grid>
        <GridItemBrand>
          <Link
            to={route.works}
            css={css`
              ${mqDown(breakpoint.mainNav)} {
                margin: auto;
              }
            `}
          >
            <Logo
              width="120"
              css={css`
                ${mqDown(breakpoint.mainNav)} {
                  width: 8rem;
                  margin: auto;
                }
                ${mqUp(breakpoint.mainNav)} {
                  width: 12rem;
                }
              `}
            />
          </Link>
          <NavButton navOpen={navOpen} onClick={toggleNavMobile} />
        </GridItemBrand>
        <GridItemNav pose={navOpen ? 'open' : 'close'}>
          <Nav>
            <NavLink to={route.works}>Réalisations</NavLink>
            <NavLink to={route.office}>Atelier</NavLink>
            <NavLink to={route.mobilier}>Mobilier</NavLink>
            <NavLink to={route.contact}>Contact</NavLink>
            <NavLinkSocials>
              <NavLinkSocialsLink to="https://www.facebook.com/R%C3%A9cita-architecture-594627804331400">
                <IconFacebook />
              </NavLinkSocialsLink>
              <NavLinkSocialsLink to="https://www.instagram.com/recita_architecture/">
                <IconInsta />
              </NavLinkSocialsLink>
              <NavLinkSocialsLink to="https://www.citedelarchitecture.fr/fr/ajap-2020">
                <IconAjap />
              </NavLinkSocialsLink>
            </NavLinkSocials>
          </Nav>
        </GridItemNav>
      </Grid>
    </Wrapper>
  </Container>
);

export default AppHeader;
