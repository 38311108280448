export enum color {
  transparent = 'transparent',

  light = '#fff',

  neutral = '#414141',
  neutralLight = '#C7C7C7',

  clr1 = '#414141',
  clr1Light = '#C7C7C7',

  borderInput = 'hsla(0, 0%, 13%, 0.125)',
  borderInputDark = 'hsla(0, 0%, 13%, 0.25)',
}

export enum fontFamily {
  primary = "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
  code = "Consolas, 'Roboto Mono', 'Liberation Mono', Menlo, Courier,",
}

export enum fontWeight {
  light = 300,
  regular = 400,
  medium = 500,
  bold = 600,
}

export enum fontSize {
  XXS = '1.2rem',
  XS = '1.4rem',
  S = '1.6rem',
  M = '1.8rem',
  L = '2rem',
  XL = '2.2rem',
  XXL = '2.4rem',
  XXXL = '2.6rem',
}

export enum lineHeight {
  XS = 1,
  S = 1.15,
  M = 1.25,
  L = 1.5,
  XL = 1.625,
}
export enum radius {
  XS = '.2rem',
  S = '.4rem',
}

export enum state {
  disabledOpacity = 0.25,
}

export enum breakpoint {
  smartphone = 480,
  tablet = 768,
  desktop = 1124,
  desktopLarge = 1780,
  mainNav = 666,
}

export const mqUp = (size: breakpoint) => `@media (min-width: ${size}px)`;
export const mqDown = (size: breakpoint) =>
  `@media (max-width: ${size + -1}px)`;

export enum header {
  heightBase = '10rem',
  heightSmall = '6rem',
}
