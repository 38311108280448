import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Link from '../base/Link';
import Wrapper from '../base/Wrapper';
import { route } from '../../constants/app';

import { mqDown, breakpoint } from '../../constants/styles';

interface FooterProps {
  props?: object;
}

const Container = styled.footer`
  padding-top: 6rem;
  padding-bottom: 3rem;
  text-align: center;
`;

const AppFooter: React.SFC<FooterProps> = props => (
  <Container {...props}>
    <Wrapper size="XL">
      <p
        css={css`
          ${mqDown(breakpoint.mainNav)} {
            font-size: 1.4rem;
          }
        `}
      >
        &copy;2019 - Récita Architecture |{' '}
        <Link to={route.legals}>Mentions légales</Link> |{' '}
        <Link to="https://www.instagram.com/recita_architecture/">
          Intragram
        </Link>{' '}
        |{' '}
        <Link to="https://www.facebook.com/R%C3%A9cita-architecture-594627804331400">
          Facebook
        </Link>
      </p>
    </Wrapper>
  </Container>
);

export default AppFooter;
